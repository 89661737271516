import { FC } from 'react';
import Link from 'next/link';
import styles from './Breadcrumbs.module.scss';

interface IBreadcrumbs {
  firstPageName: string;
  secondPageName?: string;
  thirdPageName?: string;
  firstPageAlias?: string;
  secondPageAlias?: string;
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ firstPageName, secondPageName, thirdPageName, firstPageAlias, secondPageAlias }) => {
  return (
    <ul className={styles.breadcrumbsList}>
      <li className={styles.breadcrumbsList__item}>
        <Link prefetch={false} className={styles.breadcrumbsList__itemLink} href="/">
          Главная
        </Link>
      </li>
      <li className={styles.breadcrumbsList__item}>
        {!!firstPageAlias ? (
          <Link prefetch={false} className={styles.breadcrumbsList__itemLink} href={`${firstPageAlias}`}>
            {firstPageName}
          </Link>
        ) : (
          <>{firstPageName}</>
        )}
      </li>
      {!!secondPageName && (
        <li className={styles.breadcrumbsList__item}>
          {!!secondPageAlias ? (
            <Link prefetch={false} className={styles.breadcrumbsList__itemLink} href={`${secondPageAlias}`}>
              {secondPageName}
            </Link>
          ) : (
            <>{secondPageName}</>
          )}
        </li>
      )}
      {!!thirdPageName && (
        <li className={styles.breadcrumbsList__item}>
          <span className={styles.breadcrumbsList__itemTxt}>{thirdPageName}</span>
        </li>
      )}
    </ul>
  );
};

export default Breadcrumbs;
