'use client';
import { FC } from 'react';
import Link from 'next/link';
import Layout from '@/components/layout/Layout';
import { SubmitHandler, useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import Breadcrumbs from '@/components/ui/breadcrumbs/Breadcrumbs';
import FormField from '@/components/ui/form/form-field/FormField';
import FormBtn from '@/components/ui/form/form-btn/FormBtn';
import { IMenuData } from '@/interfaces/menu.interface';
import { useActions } from '@/hooks/useActions';
import { useAuth } from '@/hooks/useAuth';
import { useRedirectToMain } from '@/hooks/useRedirectToMain';
import styles from './Login.module.scss';

const Login: FC<IMenuData> = ({ menuGenres }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { login } = useActions();
  const { user } = useAuth();

  const onSubmit: SubmitHandler<any> = (data) => {
    login(data);
    reset();
  };

  //Seo block
  const SEO_TITLE = 'Войти в личный кабинет';
  const SEO_DESCRIPTION = 'Регистрация на сайте litportal.ru';
  const SEO_CANONICAL = '/login';

  const token = Cookies.get('token');

  //Проверка на залогиненного пользователя
  if (user && token) {
    useRedirectToMain(false);
  }
  if (user && !token) {
    localStorage.removeItem('user');
  }

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref={SEO_CANONICAL} menuGenres={menuGenres}>
      <Breadcrumbs firstPageName="Войти в личный кабинет" />
      <h1 className="h1">Войти в личный кабинет</h1>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.loginForm}>
        <div className={styles.loginForm__fields}>
          <div className={styles.loginForm__item}>
            <FormField
              {...register('email', {
                required: 'Пустое поле',
              })}
              error={errors.email}
              label="Email"
              name="email"
              placeholder="Введите email"
              id="email"
              type="text"
            />
          </div>
          <div className={styles.loginForm__item}>
            <FormField
              {...register('password', {
                required: 'Пустое поле',
              })}
              error={errors.password}
              label="Пароль"
              name="password"
              placeholder="Введите пароль"
              id="password"
              type="password"
            />
          </div>
        </div>
        <div className={styles.loginForm__controls}>
          <FormBtn type="submit" value="Войти" id="login" />
          <label className={styles.loginForm__checkLabel}>
            <input className={styles.loginForm__checkInput} name="type" type="checkbox" />
            <span className={styles.loginForm__checkTxt}>Чужой компьютер</span>
          </label>
        </div>
        <div className={styles.loginForm__separator}></div>
        <div className={styles.loginForm__note}>
          По умолчанию, после удачного входа в систему пользователь будет запомнен на данном компьютере. Следующий раз вход будет произведен <span className={`${styles.loginForm__note} ${styles.loginForm__note_red}`}>автоматически</span>.
          <br />
          <b>Если Вы не хотите этого, поставьте галочку в поле "чужой компьютер".</b>
          <br />
          <br />
          <span>Если вы не зарегистрированы или забыли пароль, можете кликнуть на одну из кнопок ниже.</span>
        </div>
        <ul className={styles.loginForm__menu}>
          <li className={styles.loginForm__menuItem}>
            <Link className={styles.loginForm__menuLink} href="/registration/">
              Зарегистрироваться
            </Link>
          </li>
          <li className={styles.loginForm__menuItem}>
            <Link className={`${styles.loginForm__menuLink} ${styles.loginForm__menuLink_password}`} href="/forgot_pass/">
              Восстановить пароль
            </Link>
          </li>
        </ul>
      </form>
    </Layout>
  );
};

export default Login;
