import { NextPage, GetStaticProps } from 'next';
import Login from '@/components/screens/login/Login';
import { MENU_API } from '@/helpers/variables';
import { IMenuData } from '@/interfaces/menu.interface';

const LoginPage: NextPage<IMenuData> = ({ menuGenres }) => {
  return <Login menuGenres={menuGenres} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const menuGenresRes = await fetch(`${MENU_API.genres}`);
  const menuGenres = await menuGenresRes.json();

  return { props: { menuGenres } };
};

export default LoginPage;
